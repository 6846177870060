import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const ServicesPageTemplate = ({ data }) => {
  const {
    imageLaptopCloseUp,
    imageCanary1,
    imageCanary2,
    imageCanary3,
    page,
  } = data

  return (
    <Layout bodyClass="template-services">
      <Seo seoData={page.seo} uri={page.uri} />

      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h1 className="headline">Our Services</h1>
          </div>
        </div>
      </div>
      <div className="header-img-wrapper">
        <BackgroundImage
          preserveStackingContext
          className="header-img"
          fluid={imageLaptopCloseUp?.childImageSharp?.fluid}
        />
      </div>
      <section id="digital-solutions" className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <span>
              As a strategic partner we craft digital-first brand programs,
              interactive experiences, and eCommerce flagships that
              differentiate and resonate in today's consumer-centric, ever
              changing business environment.
            </span>
            <span className="strong">Digital Solutions.</span>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <section className="services-light pt-0">
              <BackgroundImage
                preserveStackingContext
                fluid={imageCanary1?.childImageSharp?.fluid}
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                className="row mb-5"
              >
                <div className="col d-flex align-items-center">
                  <div className="text-center">
                    <h3 className="mb-4">Branded eCommerce</h3>
                    <p>
                      Our eCommerce design starts and ends with a best-in-class
                      experience strategy that builds brands and drives
                      transactions.
                    </p>
                  </div>
                </div>
              </BackgroundImage>
              <BackgroundImage
                preserveStackingContext
                fluid={imageCanary2?.childImageSharp?.fluid}
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                className="row mb-5"
              >
                <div className="col d-flex align-items-center">
                  <div className="text-center">
                    <h3 className="mb-4">Websites & Digital Platforms</h3>
                    <p>
                      Through a deep understanding of our audience’s makeup and
                      motivations, we design digital experiences that connect
                      with people.
                    </p>
                  </div>
                </div>
              </BackgroundImage>
              <BackgroundImage
                preserveStackingContext
                fluid={imageCanary3?.childImageSharp?.fluid}
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                className="row"
              >
                <div className="col d-flex align-items-center">
                  <div className="text-center">
                    <h3 className="mb-4">Brand Strategy & Identity</h3>
                    <p>
                      We build and activate brands through cultural insight,
                      strategic vision, and the power of emotion across every
                      element of its expression.
                    </p>
                  </div>
                </div>
              </BackgroundImage>
            </section>
          </div>
        </div>
      </div>
      <section className="dark-cta pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-center text-lg-left">
              <span>
                Start your ecommerce journey, and find reasons for us to help.
              </span>
              <div className="d-md-block d-flex align-items-center justify-content-center">
                <Link
                  to="/contact/"
                  className="btn btn-primary btn-cta on-darks"
                >
                  Get In Touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPageTemplate

export const query = graphql`
  query ServicePage($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      uri
      seo {
        ...SeoData
      }
    }
    imageLaptopCloseUp: file(name: { eq: "laptop-closeup" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCanary1: file(name: { eq: "canary1" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCanary2: file(name: { eq: "canary2" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCanary3: file(name: { eq: "canary3" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
